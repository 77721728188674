/* tablet viewport */
@media screen and (min-width:768px) {
    * {
        font-size: 16px;
    }

    .auth_container main {
        max-width: 650px;
    }

    /* NAVBAR STARTS HERE */

    .navbar {
        padding-bottom: 0;
    }

    .navbar .logo {
        width: 90px;
        height: 90px;
    }

    .navbar .mobile_nav {
        width: 100%;
        font-size: .8em;
    }

    .hamburger,
    .close {
        display: none;
    }

    .navbar nav>div {
        position: relative;
        width: 100%;
        transform: translate(0);
        background-color: transparent;
    }

    .navbar nav>div ul {
        flex-direction: row;
        justify-content: center;
        gap: 1em;
    }

    .navbar .authenticate {
        flex-direction: row;
        margin-left: auto;
        justify-content: end;
    }

    .navbar .authenticate a:nth-child(1):hover {
        background-color: transparent;
        color: var(--secondary);
    }

    .navbar .authenticate a:nth-child(2):hover {
        color: var(--secondary);
        border: 0px;
        background-color: var(--primary);
        text-decoration: none;
    }

    .mobile_nav>div ul a,
    .navbar .authenticate a:nth-child(1) {
        color: white;
        border: none;
    }

    .home-link {
        margin-left: auto;
        padding-left: 10%;
    }

    /* NAVBAR ENDS HERE */



    /* FOOTER STARTS HERE */

    footer section.bottom {
        padding: 2em 2em;
        flex-direction: row;
        justify-content: space-between;
    }

    /* FOOTER ENDS HERE */



    /* HOME STARTS HERE */

    .home section.hero {
        padding: 8em 2em;
    }

    .home .hero p {
        font-size: 2em;
        max-width: 350px;
    }

    section.faqs {
        margin-left: 6em;
        margin-right: 6em;
    }

    .galleries .swiper {
        width: 90%;
        max-width: 700px;
        height: 380px;
    }

    .testimonials .swiper {
        width: 90%;
        max-width: 755px;
        height: 350px;
    }

    .testimonials .swiper-slide {
        height: 250px;
    }

    .galleries img {
        max-width: 400px;
        height: 300px;
    }

    .home .services>div {
        display: flex;
        gap: 3em;
        align-items: center;
        padding: 0 4em;
    }

    .home .services>div>div {
        flex: 1;
    }

    .home .services>div>picture>img {
        object-fit: cover;
        width: 100%;
    }

    .home .ceo {
        padding-left: 3em;
    }

    .ceo>div.left * {
        height: 100%;
        width: 350px;
    }

    .ceo>div.right {
        width: 40%;
        font-size: 1.3em;
        padding-right: 1em;
    }

    section.choose img {
        display: block;
        width: 100%;
        max-width: 350px;
    }

    section.choose>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .choose p {
        margin-right: 0;
        padding: 2em;
        padding-left: 6em;
        text-align: justify;
        width: 100%;
        border-radius: 5% 0% 0% 25% / 5% 0% 0% 95%;
    }

    .subscribe h1 {
        font-size: 1.7rem;
        margin-bottom: 1em;
    }

    .subscribe label {
        display: none;
    }

    .subscribe input {
        width: 70%;
        display: block;
        margin: 0 auto 1.5em;
    }

    /* HOME ENDS HERE */




    /* ABOUT BEGINS HERE */

    .back_logo {
        height: 450px;
    }

    .about_us {
        padding: 4em;
        text-align: justify;
    }

    .about_us h1 {
        font-size: 1.8rem;
    }

    .about_us p {
        line-height: 1.4em;
        font-size: 1.25rem;
    }

    /* ABOUT ENDS HERE */




    /* CONTACT US BEGINS HERE */

    .contact_us .info_card {
        width: 60%;
    }

    .send_message {
        padding: 3em;
    }

    .contact_us .map iframe {
        width: 95%;
        height: 450px;
        max-width: 950px;
    }

    /* CONTACT US ENDS HERE */



    /* GALLERY BEGINS HERE */

    .main_gallery .swiper {
        width: 95%;
        max-width: 800px;
        height: 500px;
    }

    .main_gallery .swiper-slide {
        height: 400px;
    }

    .main_gallery .swiper-wrapper {
        gap: .5em;
    }

    .main_gallery .swiper-button-prev {
        left: 35%;
    }

    .main_gallery .swiper-button-next {
        right: 35%;
    }


    /* GALLERY ENDS HERE */











    /* ACCOUNT PAGES BEGINS HERE */

    /* ACCOUNT NAVBAR BEGINS */


    .acc_navbar {
        background-color: white;
        padding: 0;
        position: relative;
        justify-content: start;
        align-items: start;
    }

    .acc_navbar .mobile_nav {
        height: 100%;
    }

    .acc_navbar>.logo {
        display: none;
    }

    .acc_navbar .logo {
        width: 100px;
        height: 100px;
        object-fit: cover;
    }

    .acc_navbar .acc_closer {
        position: relative;
        background-color: white;
        transform: translate(0);
        width: 6em;
        height: 100%;
        border-radius: 0px;
        justify-content: start;
        align-items: center;
        gap: 0.5em;
    }

    .acc_navbar .nav-titles {
        visibility: hidden;
        white-space: nowrap;
        transform: translateX(-10vw);
        background-color: var(--secondary);
        padding: 1em;
        border-radius: 5px;
        opacity: 0;
        transition: all 200ms;
    }

    .acc_closer a {
        padding: 0.4em 0.6em;
    }

    .acc_navbar a:hover .nav-titles {
        visibility: visible;
        opacity: 1;
        transform: translate(0);
    }

    .acc_closer a img {
        width: 100%;
        max-width: 25px;
        height: 30px;
        transform: translateX(7px);
        margin-right: .5em;
    }


    /* ACCOUNT NAVBAR ENDS */




    /* ACCOUNT PROFILE BEGINS */

    .tablet .profile {
        width: 100%;
    }

    /* ACCOUNT PROFILE ENDS */





    /* ACCOUNT DASHBOARD BEGINS */

    .tablet .dash-cta {
        width: 100%;
        text-align: center;
    }

    .dash-cta a {
        width: 200px;
    }

    /* ACCOUNT DASHBOARD ENDS */






    /* ACCOUNT BOOKING BEGINS */

    .tablet .booking span {
        font-size: 1rem;
        color: rgb(0, 0, 0, .7);
    }

    .tablet .booking-table table {
        font-size: .85rem;
        color: rgb(0, 0, 0, .7);
    }

    /* ACCOUNT BOOKING ENDS */








    .tablet {
        display: grid;
        grid-template-areas:
            'navbar main'
            'footer footer'
        ;
        grid-template-columns: 6em 1fr;
    }

    .acc_navbar {
        grid-area: navbar;
    }

    .tablet>div:nth-child(2) {
        grid-area: main;
        padding: 5em 3em 3em;
    }


    footer {
        grid-area: footer;
    }
.clients-container .client-footer>img{
    width: 250px;
    height: 300px;
}
    /* ACCOUNT PAGES ENDS HERE */

}




/* Desktop viewport */

@media screen and (min-width: 1200px) {

    * {
        font-size: 20px;
    }

    .acc_navbar .logo {
        width: 200px;
        height: 150px;
    }

    /* home styles begins */

    .home section.hero {
        padding: 12em 4em;
    }

    .home .hero p {
        font-size: 2em;
        max-width: 500px;
        line-height: 60px;
    }

    section.faqs {
        margin-left: 10em;
        margin-right: 10em;
    }

    .gallery a {
        padding: .8em;
        font-size: .9em;
        margin: 0 auto;
        transform: translateY(-50px);
    }

    .galleries .swiper {
        width: 95%;
        max-width: 1150px;
        height: 470px;
    }

    .testimonials .swiper {
        width: 95%;
        max-width: 1190px;
        height: 470px;
    }

    .testimonials .swiper-slide {
        height: 360px;
    }

    .galleries img {
        max-width: 500px;
        height: 350px;
    }

    .home .services div img {
        width: 100%;
        max-width: 800px;
    }

    section.services ul#our_services {
        transform: translate(0);
        margin: 0 auto;
        white-space: nowrap;
    }

    .services ul li {
        font-size: 1.4em;
        margin-bottom: .3em;
    }

    .services button {
        margin-top: 1.5em;
    }

    .ceo>div.left * {
        height: 800px;
        width: 700px;
    }

    .ceo>div.right {
        width: 35%;
        font-size: 1.8em;
        padding-right: .5em;
    }

    section.choose img {
        max-width: 600px;
        height: 650px;
    }

    .choose p {
        padding: 4em 1em 4em 7em;
        width: 55%;
    }

    /* home styles ends */


    /* about styles begins */

    .back_logo,
    .contact_page .back_logo {
        height: 800px;
    }

    /* about styles ends */



    /* contact styles begins */

    .contact_page .back_logo * {
        font-size: 1.5rem;
    }

    .contact_page .back_logo h2 {
        font-size: 2.2em;
    }

    .contact_us>div {
        display: flex;
        align-items: center;
        padding-right: 3em;
    }

    .contact_us>div>* {
        width: 100%;
    }

    /* contact styles ends */




    /* gallery styles begins */

    .main_gallery .swiper {
        width: 95%;
        max-width: 1200px;
        height: 600px;
        margin-bottom: 5em;
    }

    .main_gallery .swiper-slide {
        height: 500px;
    }

    .main_gallery .swiper-button-prev,
    .main_gallery .swiper-button-next {
        top: 92%;
        transform: scale(.4);
    }

    .main_gallery .swiper-button-prev {
        left: 40%;
    }

    .main_gallery .swiper-button-next {
        right: 40%;
    }

    .main_gallery .button {
        position: relative;
        top: -100px;
    }

    /* gallery styles ends */




    /* login & register styles begins */
    .pass span.open_pass {
        left: 119%;
        top: 50%;
        width: 30px;
        height: 30px;
    }

    .auth_container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;
        padding: 1em 3em;
        max-height: 100%;
    }

    .auth_container * {
        font-size: 15px;
    }

    .auth_container header {
        display: none;
    }

    .auth-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .auth_container .auth-right {
        position: relative;
        width: 700px;
        height: 90vh;
        background-image: linear-gradient(to bottom,
                rgba(0, 0, 0, 0.3) 0%,
                rgba(0, 0, 0, 0.4) 100%),
            url("./assets/images/our_services1_1.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
        border-radius: 10px;
    }

    .auth_container .auth-right img {
        object-fit: cover;
    }

    .auth_container form input {
        padding: .5em;
        padding-left: 3em;
        margin: .5em 0 .8em;
        width: 135%;
    }

    .auth_container input::placeholder {
        font-weight: 100;
        font-size: .8rem;
    }

    .auth_container form button {
        margin: 1em auto 0;
        padding: 0.6em 3em;
    }

    .auth_container .trans-right {
        transform: translateX(35%);
    }

    .auth_container a.trans-right {
        transform: translateX(0);
    }

    .auth_container>div>a {
        left: 15%;
    }

    .pass_options {
        width: 135%;
    }



    /* Desktop dashboard */
    .tablet {
        grid-template-columns: 13em 1fr;
    }

    .acc_navbar .acc_closer {
        width: 12em;
        gap: 0;
    }

    .acc_navbar .nav-titles {
        visibility: visible;
        opacity: 1;
        background-color: transparent;
        transform: translateX(0);
    }

    .acc_closer a {
        width: 90%;
        gap: 0;
        padding: 0.2em 0.6em;
        margin-bottom: .5em;
    }

    .acc_closer a img {
        margin-right: 0;
    }


    /* clients page start */
    .clients-container .clients-img img {
        max-width: 100px;
    }

    /* clients page end */

    /* Dashboard styles begins */

    .dash-stats {
        flex-direction: row;
        gap: 2em;
    }

    .tablet .dash-cta {
        width: 60vw;
    }

    .dash-cta h1 {
        margin-bottom: 0.5em;
    }

    .dash-cta p {
        text-align: center;
        margin: 0 auto;
        width: 50vw;
        line-height: 1.3em;
    }

    /* Dashboard styles ends */


    .profile form {
        margin: 1em;
    }

    footer {
        padding-bottom: 0em;
    }

    /* login & register styles ends */
}