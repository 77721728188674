:root {
	--primary: #111;
	--secondary: #f5ba31;
	--red: #eb1717;
	--light-gray: #0001;
	--deep-gray: #0008;
	--ProductSans: ProductSans, sans-serif;
}

* {
	padding: 0;
	margin: 0;
	background: 0;
	border: none;
	outline: none;
	font-size: 14px;
	list-style: none;
	box-sizing: border-box;
	text-decoration: none;
	font-family: ProductSans, serif;
}

button,
a {
	cursor: pointer;
}

/* disable arrows in number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
	appearance: textfield;
}

@font-face {
	font-family: ProductSans;
	src: url("./assets/fonts/ProductSans.ttf");
}

body {
	font-family: ProductSans, serif;
	font-size: 16px;
}

::-webkit-scrollbar {
	display: none;
}

/*########## Navbar styles #########*/
.navbar {
	background-color: var(--primary);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.5em 1em;
	position: relative;
}

.navbar .overlay {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	pointer-events: all;
	z-index: 3;
	transform: translateX(100vw);
	transition: 180ms all;
}

.navbar .overlay.active {
	transform: translate(0vw);
}

.navbar * {
	font-family: ProductSans, serif;
	font-weight: 700;
	font-size: 1.1em;
}

.navbar .logo {
	width: 70px;
	cursor: auto;
}

.navbar img {
	width: 20px;
	cursor: pointer;
}

.navbar nav>div {
	position: fixed;
	background-color: white;
	top: 0;
	right: 0;
	height: max-content;
	width: 60vw;
	transform: translateX(65vw);
	transition: 200ms all;
	border-radius: 0 0 0 10px;
	z-index: 4;
	padding-bottom: 2.5em;
}

.navbar nav>div.active {
	transform: translateX(0vw);
}

.navbar .hamburger.active {
	display: none;
}

.navbar nav>div ul {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.1em;
	padding-top: 2em;
}

.mobile_nav .close {
	margin-left: 75%;
	transform: translateY(-10%);
}

.mobile_nav>div ul a {
	color: var(--primary);
}

.mobile_nav>div ul a:hover {
	color: var(--secondary);
}

.navbar .authenticate {
	display: flex;
	flex-direction: column;
	gap: 1em;
	margin-top: 0.5em;
}

.navbar .authenticate a {
	padding: 0.4em 0.8em;
	font-size: 0.9em;
	text-align: center;
	color: var(--primary);
}

.navbar .authenticate a:nth-child(1) {
	border: 1px solid var(--secondary);
	border-radius: 10px;
	color: var(--primary);
}

.navbar .authenticate a:nth-child(2):hover {
	border: 1px solid var(--secondary);
	background-color: transparent;
	text-decoration: none;
}

.navbar .authenticate a:nth-child(1):hover {
	text-decoration: none;
	background-color: var(--secondary);
}

.navbar .authenticate a:nth-child(2) {
	color: var(--primary);
	border-radius: 10px;
	background-color: var(--secondary);
}

.mobile_nav>div ul * {
	cursor: pointer;
}

/*########## Navbar styles end #########*/

/*########## Home styles #########*/
.home {
	background-color: var(--primary);
	color: white;
}

.home section.hero {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 1em;
	color: white;
	padding: 4em 2em;
	background-image: linear-gradient(to bottom,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.5) 100%),
		url("./assets/images/Portrait.jpeg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.home .hero p {
	font-size: 1.8em;
	width: 100%;
	max-width: 500px;
}

.home .hero p span {
	color: var(--secondary);
	font-size: 1em;
}

.home .hero button,
.button {
	background-color: var(--secondary);
	color: var(--primary);
	font-weight: 700;
	padding: 1em;
	border-radius: 10px;
	cursor: pointer;
}

.home .hero button:hover {
	background-color: var(--primary);
	color: var(--secondary);
}

.home .gallery {
	margin-top: 5em;
	position: relative;
}

.gallery .right_tri {
	position: absolute;
	top: 40%;
	height: 80%;
	width: 100%;
	z-index: 1;
	transform: translateY(-50%);
	background-color: rgba(245, 186, 49, 30%);
	clip-path: polygon(100% 15%, 0% 87%, 100% 87%);
}

.home .gallery h2,
.home .services h2,
.home .testimonial h2 {
	color: var(--secondary);
	font-size: 1.5em;
	font-weight: 700;
	text-align: center;
	margin-bottom: 2em;
}

.galleries .swiper {
	height: 300px;
	width: 100%;
	max-width: 450px;
	margin: 0 auto;
	padding-left: 1.8em;
	padding-right: 1.8em;
}

.galleries .swiper .swiper-slide:nth-child(3) img {
	object-position: top;
}


.galleries .swiper-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
}

.galleries .swiper-pagination-bullet {
	background: white;
	position: relative;
	top: -30px;
	opacity: .8;
	width: 6px;
	height: 6px;
}

.galleries .swiper-pagination-bullet-active {
	background: var(--secondary);
	width: 10px;
	height: 10px;
	opacity: 1;
}

.galleries img {
	width: 100%;
	max-width: 350px;
	aspect-ratio: 4/3;
	object-fit: cover;
	border-radius: 10px;
	display: block;
	position: relative;
	z-index: 2;
}

.gallery a {
	color: var(--secondary);
	border: 1px solid var(--secondary);
	border-radius: 10px;
	padding: 1em;
	display: block;
	margin: .5em auto 0;
	text-align: center;
	width: fit-content;
	cursor: pointer;
	white-space: nowrap;
	position: relative;
	z-index: 3;
}

.home .services {
	margin-top: 4em;
}

.home .services div img {
	width: 90%;
	display: block;
	border-radius: 10px;
	margin: 0 auto;
}

section.services ul#our_services {
	color: var(--secondary);
	font-weight: 600;
	display: block;
	margin: 2em auto;
	width: 200px;
	transform: translate(30px);
}

.services ul li {
	list-style-type: disc;
	font-size: 1.2em;
}

.services button {
	background-color: var(--secondary);
	color: var(--primary);
	padding: 1em 0;
	display: block;
	width: 150px;
	margin: 0 auto;
	font-weight: 700;
	border-radius: 10px;
}

.home .ceo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 8em;
	padding-left: 1em;
	position: relative;
}

.ceo>div.left {
	position: relative;
}

.ceo>div.left * {
	height: 220px;
	width: 150px;
}

.ceo>div.left img {
	position: relative;
	z-index: 2;
	object-fit: cover;
	object-position: top;
}

.ceo>div.left>div {
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 1;
	background-color: var(--secondary);
}

.ceo>div.right {
	width: 55%;
	background-color: var(--secondary);
	color: var(--primary);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	padding-right: 0.2em;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	height: 150%;
	clip-path: polygon(100% 0, 0 50%, 100% 100%);
}

.ceo>div.right h5,
.ceo>div.right h6 {
	font-weight: 700;
	font-size: 0.8em;
}

/* faqs */

section.faqs {
	margin: 8em 1.2em 3em;
	color: var(--secondary);
	text-align: center;
	font-size: .8rem;
}


.faqs .head {
	font-size: 1.5rem;
	margin-bottom: 1em;
}


.question {
	margin-bottom: 1em;
	border-radius: 5px;
}

.question-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .5em;
	cursor: pointer;
	background-color: var(--secondary);
	color: var(--primary);
	border-radius: 5px 5px 0 0;
	text-transform: uppercase;
	text-align: left;
	line-height: 1.5em;
	word-spacing: .3em;
}

img.faq-drop-arrow,
img.drop-arrow {
	transition: 200ms all;
}

img.faq-drop-arrow.active,
img.drop-arrow.active {
	transform: rotateZ(180deg);
}

.question-header h3 {
	margin: 0;
	line-height: 1.4em;
	font-size: .8rem;
}


.question-content {
	padding: 1em;
	background-color: rgba(255, 255, 255, .95);
	color: var(--primary);
	border-radius: 0 0 5px 5px;
	transform: translateY(-100px);
	display: none;
	text-align: justify;
	line-height: 1.5em;
	font-size: .9rem;
}

.question-content.active {
	transform: translateY(0px);
	display: block;

}

/* faqs */



/* testimonials */
.testimonials .swiper {
	height: 260px;
	width: 100%;
	max-width: 450px;
	margin: 0 auto;
	padding-left: 1.8em;
	padding-right: 1.8em;
}

.testimonials .swiper-slide {
	background-color: var(--secondary);
	padding: 1.5em 1em;
	border-radius: 10px;
	color: var(--primary);
	height: 210px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1em;
}

.testimonials .swiper-slide h3 {
	font-size: 1.3em;
}

.testimonials .swiper-pagination {
	display: none;
}



section.choose {
	color: var(--secondary);
}

section.choose img {
	display: none;
}

section.choose h1 {
	font-size: 1.4em;
	text-align: center;
}

.choose p {
	padding: 1.5em;
	background-color: var(--secondary);
	color: var(--primary);
	width: 90%;
	margin: 1em auto;
	border-radius: 10px;
	font-family: var(--ProductSans);
	word-spacing: 3px;
	line-height: 1.5em;
}

section.subscribe {
	color: var(--secondary);
	margin: 8em 0 0;
	padding: 0 1em 8em;
}

.subscribe h1 {
	font-size: 1.4em;
	text-align: center;
	margin-bottom: 0.5em;
}

.subscribe label {
	display: block;
	margin-bottom: 0.5em;
}

.subscribe input {
	border: 1px solid var(--secondary);
	padding: 1em;
	width: 100%;
	border-radius: 10px;
	background-color: white;
	margin-bottom: 1.3em;
}

.subscribe button {
	display: block;
	margin: 1em auto 0;
	width: 200px;
	background-color: var(--secondary);
	color: var(--primary);
	padding: 1em 0;
	font-weight: bold;
	border-radius: 10px;
}

/*########## HOME STYLES END #########*/




/* ABOUT US STYLES BEGINS */

.back_logo {
	height: 275px;
	width: 100%;
	margin: 0 auto;
	background-image: linear-gradient(to bottom,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.5) 100%),
		url("./assets/images/back_logo.svg");
	background-repeat: no-repeat;
	background-position: left;
	background-size: cover;
}

.about_us {
	padding: 1em 2em 3em;
	background-color: var(--primary);
	color: white;
}

.about_us h1 {
	font-size: 1.5rem;
	margin-bottom: 1.5em;
	color: var(--secondary);
}

.about_us p {
	font-family: var(--ProductSans);
	line-height: 1.3em;
}


/* ABOUT US STYLES ENDS */





/* CONTACT US STYLES BEGINS */

.contact_page {
	background-color: var(--primary);
}

.contact_page .back_logo {
	display: flex;
	flex-direction: column;
	gap: .5em;
	align-items: center;
	justify-content: center;
	color: white;
	text-align: center;
	background-image: linear-gradient(to bottom,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.5) 100%),
		url("./assets/images/back_logo2.png");
}

.contact_page .back_logo h2 {
	font-size: 1.8em;
	color: var(--secondary);
}

.contact_page .back_logo .button {
	margin-top: .5em;
}

.contact_us {
	margin-top: 5em;
}

.contact_us section {
	margin-bottom: 5em;
}

.contact_us .info_card {
	background-color: white;
	height: fit-content;
	border-radius: 10px;
	display: flex;
	gap: .5em;
	justify-content: left;
	align-items: center;
	width: 70%;
	margin: 0 auto 1em;
	padding: 1em 0 1em 1.5em;
}

.contact_us .info_card span {
	font-family: var(--ProductSans);
}

.send_message {
	padding: .8em;
}

.send_message h3 {
	font-family: var(--ProductSans);
	color: var(--secondary);
	margin-bottom: 1em;
}

.send_message form {
	display: flex;
	flex-direction: column;
	gap: 1em;
}

.send_message form input,
.send_message form textarea {
	background-color: white;
	padding: 1em;
	font-family: var(--ProductSans);
	border: 2px solid var(--secondary);
	border-radius: 10px;
}

.send_message form textarea {
	height: 150px;
}

.send_message form input::placeholder {
	font-family: var(--ProductSans);
}

.send_message button {
	width: 120px;
	font-family: var(--ProductSans);
	font-weight: 400;
}

.contact_us .map h1 {
	text-align: center;
	font-size: 1.4em;
	color: var(--secondary);
	margin-bottom: 2em;
}

.contact_us .map iframe {
	width: 90%;
	max-width: 600px;
	height: 300px;
	display: block;
	margin: 0 auto;
	border: 0;
	border-radius: 10px;
}

/* CONTACT US STYLES ENDS */




/* GALLERY STYLES BEGINS */
.main_gallery {
	background-color: var(--primary);
}

.main_gallery header h1 {
	font-size: 1.5em;
	text-align: center;
	background-color: var(--primary);
	color: var(--secondary);
	text-transform: uppercase;
}

.main_gallery main {
	min-height: 80vh;
	margin-top: 5em;
}

.main_gallery .swiper {
	width: 90%;
	max-width: 400px;
	height: 480px;
	border-radius: 5px;
}

.main_gallery .swiper-slide {
	height: 375px;
}


.main_gallery .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	margin-right: 1em;
	object-fit: cover;
	border-radius: 10px;
}

.main_gallery .swiper-pagination-bullet {
	width: 20px;
	height: 30px;
	font-size: 12px;
	color: rgb(255, 255, 255, 50%);
	opacity: .5;
	border-radius: 5px;
	line-height: 30px;
	background-color: transparent;
	transform: translateY(-24px);
}

.main_gallery .swiper-pagination-bullet-active {
	color: var(--primary);
	background: var(--secondary);
	font-weight: bold;
	opacity: 1;
}

.main_gallery .swiper-button-prev,
.main_gallery .swiper-button-next {
	color: white;
	top: 90%;
	transform: scale(.3);
}

.main_gallery .swiper-button-prev {
	left: 18%;
}

.main_gallery .swiper-button-next {
	right: 18%;
}

.main_gallery .button {
	display: block;
	width: fit-content;
	margin: 0 auto;
}

.main_gallery footer {
	margin-top: 2em;
}

/* GALLERY STYLES ENDS */





/* """"""""""" FOOTER STYLES BEGINS """"""""" */

footer {
	padding-bottom: 2em;
	background-color: var(--secondary);
}

footer .top {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1em;
	padding: 2em 0;
}

footer .top a {
	color: var(--primary);
	/* cursor: pointer; */
	font-weight: 700;
}

footer .top a:hover {
	text-decoration: underline;
}

footer hr {
	height: 1px;
	background-color: var(--primary);
}

footer .bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1em 0 0;
	gap: 1em;
	font-weight: 700;
}

footer .bottom .left {
	display: flex;
	align-items: center;
	gap: 1.5em;
}


/* """"""""""" FOOTER STYLES ENDS """"""""" */






/*######## LOGIN AND REGISTER STYLES ########*/
.auth_container {
	background-color: var(--primary);
	color: white;
	padding: 0 0 50px 1em;
	height: 100dvh;
	position: relative;
}

.auth_container header img {
	display: block;
	margin: 0 auto;
}

.auth_container header img,
.logo {
	width: 100%;
	max-width: 120px;
}

.auth_container main {
	max-width: 500px;
	margin: 0 auto;
}

.auth_container section span {
	display: block;
}

.auth_container section span:nth-child(1) {
	font-size: 20px;
	font-weight: 900;
	color: var(--secondary);
}

.auth_container section span:nth-child(2) {
	font-size: 16px;
}

.auth_container form {
	margin-top: 20px;
	width: 95%;
}

.auth_container form label {
	font-weight: bold;
}

.auth_container form label::after {
	content: "*";
	color: var(--red);
	font-size: 0.9em;
	position: relative;
	bottom: 2px;
}

.auth_container form label[for='remember']::after {
	content: '';
}

.auth_container form input {
	background-color: white;
	padding: 1em;
	display: block;
	margin: 0.5em 0 1em;
	width: 100%;
	border: 1px solid var(--secondary);
	border-radius: 10px;
	outline: 0;
	background-size: 20px;
	padding-left: 3.5em;
	font-family: ProductSans, serif;
	font-size: 1.2em;
}

.auth_container form input[type="email"] {
	background: white url("./assets/images/ic_outline-email.png") 5% no-repeat;
}

input::placeholder {
	font-family: ProductSans, serif;
	font-weight: 900;
	color: #0000004d;
}

.auth_container .pass {
	position: relative;
}

.pass span.open_pass {
	position: absolute;
	right: 5.3%;
	top: 55%;
	background-color: #00000001;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	cursor: pointer;
}

.auth_container form input[type="password"] {
	background-image: url("./assets/images/mdi_password-outline.png"),
		url("./assets/images/eye_closed.svg");
	background-color: white;
	background-repeat: no-repeat;
	background-position: 5%, 95%;
}

.auth_container form input[type="text"].active {
	background-image: url("./assets/images/mdi_password-outline.png"),
		url("./assets/images/eye_open.svg");
	background-color: white;
	background-repeat: no-repeat;
	background-position: 5%, 95%;
}

.auth_container .auth-left {
	padding-bottom: 2.5em;
}

.auth_container>div>a {
	position: absolute;
	bottom: .8em;
	white-space: nowrap;
	color: white;
	left: 50%;
	transform: translate(-50%);
}

.auth_container>div>a:hover,
.pass_options .forget:hover {
	text-decoration: underline;
	cursor: pointer;
}

.auth_container>div>a span {
	color: var(--secondary);
}

.auth_container form button {
	background-color: var(--secondary);
	color: var(--primary);
	font-family: ProductSans;
	font-weight: bold;
	display: block;
	margin: 2em auto 0;
	border-radius: 10px;
	padding: 0.6em 2em;
}

.pass_options {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 1em;
	width: 100%;
}

.auth_container form .remember label,
.pass_options .forget {
	font-weight: bold;
}

.auth_container .remember {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
}

.remember *,
.pass_options .forget {
	cursor: pointer;
	white-space: nowrap;
}

.forget {
	color: var(--secondary);
}

label[for="remember"] {
	display: grid;
	grid-template-columns: 10% 90%;
	align-items: center;
	gap: .4em;
}


/* Styling the check button */
input[type="checkbox"] {
	visibility: hidden;
}

label[for="remember"] span.custom {
	position: absolute;
	top: 45%;
	transform: translateY(-50%);
	left: 0;
	height: 12px;
	width: 12px;
	background-color: white;
	z-index: 0;
}

label[for="remember"] input[type="checkbox"]:checked~span.custom {
	background-color: var(--secondary);
}

label[for="remember"] span.custom::after {
	content: "";
	position: absolute;
	display: none;
	left: 25%;
	top: 3.5%;
	width: 3px;
	height: 6px;
	border: 2px solid var(--primary);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

label[for="remember"] input[type="checkbox"]:checked~span.custom::after {
	display: block;
}


/* Password modal */
.password-modal {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	position: fixed;
	top: 0;
	left: 0;
	padding: 0 .5em;
	transform: scale(0);
	transform-origin: top right;
	transition: all 200ms;
	color: var(--primary);
	text-align: center;
}

.password-modal.active {
	transform: scale(1);
}

.password-modal-content {
	position: relative;
	z-index: 5;
	background-color: white;
	padding: 1.5em 2em;
	border-radius: 10px;
}

.password-modal-content h1 {
	margin: .3em;
}

.password-modal-content input {
	border-radius: 10px;
	border: 1px solid var(--light-gray);
	font-size: 1.3rem;
	padding: .6em;
	margin: 1.3em 0 1em 0;
	width: 100%;
}

.password-modal-content input::placeholder {
	font-weight: 400;
	color: var(--deep-gray);
}

.password-modal-content a {
	width: 90%;
	display: block;
	margin: 0 auto;
}



/*######## Login and Register Styles ended ########*/










/* ACCOUNT PAGES STYLES BEGINS */


/* ACCOUNT NAVBAR STARTS */

.acc_navbar {
	background-color: var(--primary);
	padding: 0em 1em;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.acc_navbar .mobile_nav {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.acc_navbar .logo {
	width: 50px;
	height: 100%;
	object-fit: cover;
}

.acc_navbar .hamburger {
	width: 15px;
	cursor: pointer;
}

.acc_navbar .hamburger.active {
	display: none;
}

.acc_navbar .acc_closer {
	position: fixed;
	background-color: white;
	top: 0;
	left: 0;
	height: max-content;
	width: 60vw;
	max-width: 350px;
	transform: translateX(-65vw);
	transition: 200ms all;
	border-radius: 0 0 10px 0;
	z-index: 4;
	padding-bottom: 2.5em;
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	transition: 200ms all;
}

.acc_closer.active {
	transform: translateX(0vw);
}

.acc_navbar #overlay.active {
	height: 100vh;
	width: 100vw;
	background-color: rgba(15, 4, 4, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: all;
}

.acc_closer a {
	width: 60%;
	padding: 1em 0 1em .9em;
	border-radius: 10px;
	color: var(--primary);
	display: flex;
	align-items: center;
	gap: .5em;
	font-weight: 700;
	margin: 0 auto;
}

.acc_closer a:hover {
	background-color: var(--secondary);
}

.acc_closer .opened_nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1em 0 1.5em;
}

.acc_closer .opened_nav .close {
	margin-left: 0;
	width: 15px;
	cursor: pointer;
	transform: translateY(0%);
}

/* ACCOUNT NAVBAR ENDS */



/* DASHBOARD STYLES BEGINS */
.dashboard {
	min-height: 80vh;
	background-color: var(--light-gray);
	padding: 5em 1em;
}

.dashboard * {
	font-family: var(--ProductSans);
}

.dashboard h1 {
	text-align: center;
	font-size: 1.3em;
	margin-bottom: 2em;
}

.dash-stats {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5em;
}

.dash-stats-card {
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 1.5em 1em;
	border-radius: 10px;
}

.dash-stats-card span {
	display: block;
	font-weight: bold;
	font-size: 1.1em;
}

.dash-stats-card span:nth-child(1) {
	color: var(--deep-gray);
}

.dash-cta {
	margin-top: 4em;
}

.dash-cta h1 {
	font-size: 1.7em;
	width: 67%;
	margin: 0 auto 1em;
	text-align: center;
}

.dash-cta p {
	line-height: 22px;
}

.dash-cta a {
	display: block;
	margin: 1em auto 0;
	background-color: var(--secondary);
	color: var(--primary);
	padding: 1.2em;
	width: 120px;
	text-align: center;
	border-radius: 10px;
}

/* DASHBOARD STYLES ENDS */




/* BOOKING STYLES BEGINS */

.booking {
	background-color: var(--light-gray);
	min-height: 75vh;
	padding: 5em 1em;
	text-align: center;
}

.booking * {
	font-family: var(--ProductSans);
	font-size: .9em;
}

.booking h1 {
	font-size: 1.5em;
}

.booking span {
	display: block;
	margin-top: 1em;
}

.booking-table {
	background-color: white;
	width: 100%;
	margin-top: 3em;
	padding: 2em 1em;
	border-radius: 10px;
	box-shadow: 20px 20px 60px #bebebeda,
		-20px -20px 60px #fff;
	display: flex;
	flex-direction: column;
	gap: 2em;
}

.booking-table table tr {
	display: flex;
	align-items: center;
	padding-bottom: 1.5em;
	margin-bottom: 1em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.booking-table table thead tr {
	padding: 0;
	border: 0;
	font-size: 1em;
}

.booking-table th {
	width: 100%;
	font-weight: 100;
	display: block;
	white-space: nowrap;
	text-transform: uppercase;
	color: var(--deep-gray);
}

.booking-table td {
	width: 100%;
	text-transform: uppercase;
}

.booking-table span.booked {
	color: var(--deep-gray);
}

/* BOOKING STYLES ENDS */





/* PROFILE STYLES BEGINS */

.profile-container {
	min-height: 80vh;
	background-color: var(--light-gray);
	padding-top: 5em;
}

.profile {
	background-color: white;
	width: 90%;
	margin: 0 auto;
	padding: 1.5em 1em;
	border-radius: 5px;
}

.profile button,
.profile a {
	font-weight: 100;
}

.profile header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.profile header h1 {
	font-size: 1.6em;
}

.profile form {
	margin: 5em 1em 0;
}

.profile form input {
	width: 100%;
	padding: 1em;
	border: 1px solid var(--light-gray);
	border-radius: 10px;
	margin-bottom: 1em;
}

.profile form button {
	background-color: var(--secondary);
	color: var(--primary);
	border-radius: 10px;
	text-align: center;
	display: block;
	padding: 1.3em 2.7em;
	margin: 1em auto 0;
}

/* PROFILE STYLES ENDS */





/* SERVICE STYLES BEGINS  */

.main-services {
	display: flex;
	flex-direction: column;
	gap: 1em;
	text-align: center;
	padding: 4em .5em 1.5em;
	min-height: 75vh;
}

.main-services * {
	color: var(--primary);
}

.main-services h1 {
	font-size: 1.5rem;
}

.main-services p {
	font-size: 1.1rem;
}

.main-services .service-cards {
	margin-top: 3em;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2em;
	padding: 0 1em;
}

.main-services .service-card {
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	justify-content: center;
	align-items: center;
	padding: 2em 0;
	border-radius: 10px;
	background: linear-gradient(145deg, #ffffff, #efefef);
	box-shadow: 20px 20px 60px #c9c9c9,
		-20px -20px 60px #ffffff;
}

/* SERVICE STYLES ENDS  */






/* SUB SERVICES STYLES BEGINS */

.sub-services {
	min-height: 75vh;
	background-color: var(--light-gray);
	padding-bottom: 3em;
}

.sub-services * {
	margin: 0;
	padding: 0;
	box-shadow: border-box;
}

.sub-services>div>header,
header.details-header {
	text-align: center;
	padding: 5em 0 3em;
}

.sub-services>div>header p.title-desc {
	padding: 0 5em;
	margin: 1em 0 0;
}

.sub-services .subservice-cards {
	padding: 0 1em;
}

.subservice-card {
	background-color: white;
	padding: 2.2em 1.7em 1.7em;
	border-radius: 10px;
	margin: 1em 0;
}

.subservice-card>header>p {
	margin: 1em 0 0;
}

.subservice-card>header>h4 {
	margin: .5em 0;
}

.subservice-card .package-body h2 {
	margin: 1em 0;
	font-size: 1.2em;
}

.subservice-card .packageDetail {
	margin-bottom: .7em;
}

.subservice-card .packageDetail span,
.pop-data span {
	display: inline-block;
	margin-right: .5em;
}

.subservice-card .duration-options {
	margin-bottom: .6em;
}

.subservice-card a.button {
	display: block;
	width: fit-content;
	padding: 1em 1.3em;
	margin-top: 1em;
	white-space: nowrap;
}

.subservice-card button.button {
	padding: 1em 1.3em;
	margin-top: 1em;
}

.package-body select {
	border: 1px solid var(--primary);
	width: 100%;
	padding: .7em 0.5em;
}



.details-container {
	min-height: 75vh;
	background-color: var(--light-gray);
	padding: 0 0 3em;
}

.details-body {
	padding: 0 1.2em;
}

.details-body form {
	background-color: white;
	padding: 2em 1.5em;
	border-radius: 10px;
}

.details-body form label {
	font-size: 1.05em;
	font-weight: 700;
}

.details-body form input {
	padding: .7em;
	display: block;
	border: 1px solid var(--deep-gray);
	width: 100%;
	margin: 0.5em auto 1em;
	border-radius: 10px;
	font-size: 1.1em;
}

.details-body form button {
	margin: 0 auto;
	display: block;
	font-weight: 100;
}

.details-body form button:disabled {
	opacity: .3;
	color: black;
	background-color: var(--deep-gray);
}





/* POP UP */
.modal {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	position: fixed;
	top: 0;
	left: 0;
	padding: .5em;
	transform: scale(0);
	transform-origin: top left;
	transition: all 200ms;
}

.modal.active {
	transform: scale(1);
}

#modal-overlay {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	pointer-events: all;
}

.modal-content {
	position: relative;
	z-index: 5;
	padding: 2em 1em 1.5em 1em;
	background-color: white;
	border-radius: 10px;
}

.modal-content h2 {
	font-size: 1.2rem;
	color: #EB1717;
	margin-bottom: .7em;
}

.modal-content .pop-data {
	margin-bottom: .5em;
	line-height: 1.4em;
}

.modal-content .footer {
	margin-top: 1em;
}

.modal-content p {
	line-height: 1.3em;
}

.modal-content .footer a,
.modal-content a {
	display: block;
	margin: 0 auto;
	text-align: center;
	padding: 1em 0;
	border-radius: 10px;
	color: var(--primary);
	font-weight: 700;
	font-size: 1.1em;
	margin: 1.5em 0 .3em 0;
	background-color: var(--secondary);
}


/* resetting the radio buttons starts */

/* Customize the label (the container) */
.duration-options {
	display: block;
	position: relative;
	padding-left: 1.5em;
	margin-bottom: .6em;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.duration-options input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom radio button */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 14px;
	width: 14px;
	background-color: #eee;
	border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.duration-options:hover input~.checkmark {
	background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.duration-options input:checked~.checkmark {
	background-color: var(--primary);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.duration-options input:checked~.checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.duration-options .checkmark:after {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}

/* resetting the radio buttons ends */

/* SUB SERVICES STYLES ENDS */





/* STUDIO STYLES BEGINS */

/* DROPDOWN */
.dropdown-head {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 1em;
	background-color: rgba(0, 0, 0, 0.05);
}

.dropdown-body {
	display: none;
	margin: 1.5em 0;
	background-color: rgba(0, 0, 0, 0.05);
	padding: 1em;
	flex-direction: column;
	gap: 1em;
	font-weight: bold;
}

.dropdown-body.active {
	display: flex;
}

.dropdown-body p {
	margin-bottom: .5em;
}

.dropdown-body span {
	display: block;
	margin: 1em 0 0;
	background-color: black;
	height: 1px;
	width: 100%;
}

.dropdown-body>div:last-child>span {
	display: none;
}


/* STUDIO STYLES ENDS */



/* CLIENTS PAGE STARTS */


.clients-container .hero-section {
	padding: 13em 0;
	background-image: linear-gradient(to bottom,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.5) 100%),
		url("./assets/images/clientHeroImg.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.clients-container header h2 {
	background-color: var(--primary);
	color: var(--secondary);
	padding: 1em 0;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.5rem;
}


.clients-container .client-body {
	padding: 2em 1em;
	line-height: 1.5em;
}

.clients-container .clients-img {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 1em;
	gap: 2em 1em;
	margin-bottom: 5em;
}

.clients-container .clients-img img {
	width: 100%;
	max-width: 80px;
}

.clients-container .client-footer {
	position: relative;
	top: 1em;
}

.clients-container .client-footer>img {
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 1%;
	width: 90px;
	height: 150px;
	transform: translateY(-50%);
}



/* CLIENTS PAGE ENDS */


/* ACCOUNT PAGES STYLES ENDS */